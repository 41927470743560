dead-purchase-history {
    .datatable-summary-row {
        .datatable-checkbox {
            visibility: hidden;
        }
    }

    .popover {
        width: 100%;
        max-width: 100%;
    }
}
