dead-purchase-history-analytics {
    .line-chart-container {
        min-width: 768px;
        height: 500px;
        .ngx-charts {
            height: 420px;
            // min-width: 768px;
            // max-width: 100%;
            // width:100%
        }
    }

    .advanced-pie-chart-container {
        display: flex;
        flex-direction: column;
        .ngx-charts {
            height: 250px;
            // min-width: 768px;
            // max-width: 100%;
            // width:100%
        }
        // .advanced-pie-legend .legend-items-container .legend-items {
        //     overflow: inherit;
        // }
        .advanced-pie-legend {
            .total-value {
                font-size: 15px;
            }
            .total-label {
                font-size: 15px;
            }
            .legend-items-container {
                .legend-items {
                    .item-value {
                        font-size: 15px;
                    }
                    .item-percent {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
