/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/dist/css/bootstrap.min.css";

/* CSS Dead Template  */
@import "./app/admin/send-email/send-email.component.scss";
@import "./app/shared/credit-card/credit-card.component.scss";
@import "./app/admin/purchase-history/purchase-history.component.scss";
@import "./app/admin/questions/questions.component.scss";
@import "./app/frequently-asked-questions/frequently-asked-questions.component.scss";
@import "./app/admin/purchase-history/purchase-history-analytics/purchase-history-analytics.component.scss";
@import "./assets/variables";

.modal-backdrop {
  z-index: 1040 !important;
}

// overwriting global styles for ng-select
.ng-select {
  .ng-select-focused {
    border-color: none !important;
    box-shadow: none !important;
  }

  padding-top: 7px;

  .ng-select-container {
    border: 0 !important;
    box-shadow: none !important;
  }
}

.is-invalid {
  .ck-content {
    border-color: $red !important;
  }
}

.is-valid {
  .ck-content {
    border-color: $primary !important;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

.popover-header {
  text-align: center;
}

/*!

 =========================================================
 * Light Bootstrap Dashboard Angular - v1.6.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-angular2
 * Copyright 2016 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "styles/variables";
@import "styles/mixins";

@import "styles/buttons";
@import "styles/cards";
@import "styles/chartist";

.spinner-outter {
  position: relative !important;
  min-height: 250px !important;
}

.spinner-text {
  color: black !important;
  padding-top: 50px !important;
}

.loading {
  text-align: center;
  margin-top: 50px;
}

.loading-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

fieldset {
  display: block;
  border-width: 2px;
  border-style: groove;
  border-color: threeface;
  border-image: initial;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.25em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

legend {
  display: block;
  width: unset;
  max-width: unset;
  padding: 2px;
  margin-bottom: unset;
  font-size: unset;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.purchase-analytics-tooltip-month-template {
  width: 300px;
}

.toast-container {
  pointer-events: auto;
}

.custom-toaster-item {
  &-error,
  &-success {
    padding: 6px 16px;
    border-radius: 6px;
    margin-bottom: 10px;

    .action-accept,
    .action-reject {
      border-radius: 5px;
      padding: 2px 15px;
      margin: 5px 0;
      cursor: pointer;
    }
  }

  &-message {
    font-size: 13px;
  }
}

.custom-toaster-item-error {
  background-color: #ffe2e2;

  .toast-close-button {
    color: #ee2d20;
  }

  .custom-toaster-item-message {
    color: #ee2d20;
  }

  .action-accept,
  .action-reject {
    border: 1px solid #ee2d20;
  }
}

.custom-toaster-item-success {
  background-color: #e1effe;

  .toast-close-button {
    color: #2a44c8;
  }

  .custom-toaster-item-message {
    color: #2a44c8;
  }

  .action-accept,
  .action-reject {
    border: 1px solid #2a44c8;
  }

  .action-accept {
    color: #ffffff;
    background: #2a44c8;
    margin-right: 10px;
  }

  .action-reject {
    color: #2a44c8;
    background: #ffffff;
  }
}
